import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'

import { SendForgotPasswordMailAction,SaveUserForgotPasswordAction } from '../Action/action';


const ForgotPassword = () => {
	const [form, setForm] = useState({ email: '', forgot_password_otp: '', password: '' });
	const [ForgotPasswordMailSent, setForgotPasswordMailSent] = useState(0);
	const [validatioError, setvalidatioError] = useState({});
	
	if (Cookies.get('loginSuccessMember')) {
		window.location.href = `${config.baseUrl}`;
	}
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
	function validate_resend_otp() {
		let emailError = "";
		// let forgot_password_otpError = "";
		// let passwordError = "";
		
		if (form.email === '') {
			emailError = "Email is required."
		}
		
		if (emailError) {
			setvalidatioError({
				emailError
			})
			
			return false
		} else {
			return true
		}
	}
	
	function validate() {
		let emailError = "";
		let forgot_password_otpError = "";
		let passwordError = "";
	
		if (form.email === '') {
			emailError = "Email is required."
		}
		
		if (ForgotPasswordMailSent > 0 && form.forgot_password_otp === '') {
			forgot_password_otpError = "OTP is required."
		}
				
		if (ForgotPasswordMailSent > 0 && form.password === '') {
			passwordError = "Password is required."
		}
				
		if (emailError || forgot_password_otpError || passwordError) {
			setvalidatioError({
				emailError,forgot_password_otpError,passwordError
			})
			
			return false
		} else {
			return true
		}
	}
	
	const send_FPM = async () => {
		let res = await SendForgotPasswordMailAction(form);
		if (res.success) {
			toast.success(res.msg);
			setForgotPasswordMailSent('1');
		} else {
			toast.error(res.msg);
		}
	}
	
	const saveUserForgotPassword = async () => {
		let res = await SaveUserForgotPasswordAction(form);
		if (res.success) {
			toast.success(res.msg);
			setTimeout(() => {
				window.location.href=`${config.bannersUrl}`
			}, 2000);
			
		} else {
			toast.error(res.msg);
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {
	
		}
		else {
			
			if (ForgotPasswordMailSent > 0)
			{
				saveUserForgotPassword();
			}
			else
			{
				send_FPM();
			}
		}
	}
	
	const ResendOTPForm = async () => {
		const isValid = validate_resend_otp();
		if (!isValid) {
	
		}
		else {
			send_FPM();
		}
	}
	
	//~ function validateEmail(email) {
		//~ // Regular expression for email validation
		//~ const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
		//~ return regex.test(email);
	//~ }
	
	return(
		<>
			<Header />
			
			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />
							
							<div className="classification-section">
								<h2 className="page-heading">Member Forgot Password</h2>
								
								
								<form action='' method='post'>
									<div className="bondiclassified-fromsection">
										<div className="catagoryfrom">
										<div className="member-login">
											<div className="row">
												<div className="col-md-12 col-sm-12 user">
													<img src={config.baseUrl + "img/user.png"} alt='img' />
												</div>
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														{/* <label for="memberemail">Email</label> */}
														<input type="text" className="form-control" name="email" id="email" placeholder="Enter Your Email" onChange={inputHandler} />
														<span className="text-danger">{validatioError.emailError}</span>
													</div>
												</div>
											</div>
											
											
											
												
												{ForgotPasswordMailSent > 0 ?
												
												<div className="row">
												
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														{/* <label for="memberpassword">Password</label> */}
														<input type="text" className="form-control" name="forgot_password_otp" id="forgot_password_otp" placeholder="Enter Your OTP" onChange={inputHandler} />
														
														<span className="text-danger">{validatioError.forgot_password_otpError}</span>
														
														<Link to="#" onClick={(e) => { 
															e.preventDefault();
															ResendOTPForm(); 
														  }} className="forgot text-right d-block w-100">Resend OTP</Link>
														
													</div>
												
													
												</div>											
												
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														{/* <label for="memberpassword">Password</label> */}
														<input type="password" className="form-control" name="password" id="password" placeholder="Enter Your Password" onChange={inputHandler} />
														
														<span className="text-danger">{validatioError.passwordError}</span>
													</div>
												</div>											
												
												</div>	
												
												: ''}
											<div className="row">	
												<div className="col-md-12 mt-3">
													<button type="submit" onClick={SubmitForm} className="btn btn-primary login-btn">Submit</button>
													
												</div>
												<div className="col-md-12">
												<Link to={config.baseUrl + 'login'} className="btn btn-primary login-btn fb">Login</Link>
												
													<Link to={config.baseUrl + 'register'} className="btn btn-primary login-btn reg">Register Now</Link>
												</div>
											</div>
										</div>
									</div>
									</div>
								</form>
							
							
							
							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>
			
			<Footer />
		</>
	)
	
}

export default ForgotPassword;
