import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import CustomPagination from './CustomPagination';
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { SearchAlladsAction, SearchAlladsCountAction, SaveSearchHistoryAction } from '../Action/action';
const ageVerified = (!Cookies.get('ageVerified')) ? false : Cookies.get('ageVerified');
const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));


const SearchAds = () => {
	const { title } = useParams();
	const { id } = useParams()
	const { category } = useParams();
	const { state } = useParams();
	const { suburb } = useParams();
	const [page, setPage] = useState(1);
	const [totalPages, settotalPages] = useState(0);
	const [totalRecords, settotalRecords] = useState(0);
	const [catDetails, setCatDetails] = useState({});
	const [allpaidAds, setpaidAds] = useState({});
	const [allfreeAds, setfreeAds] = useState({});
	const [adUrl, setAdUrl] = useState('');
	const [spinloader, setspinloader] = useState(0);
	const [pagingspinloader, setpagingspinloader] = useState(1);

	useEffect(() => {
		setspinloader(1)
		getAllAdsAPI();
		getAllAdsCountAPI();
	


	}, [])

	const getAllAdsAPI = async () => {
		let res = await SearchAlladsAction({ title: title, category: category, state: state, suburb: suburb, page: page });
		if (res.success) {
			const freeAds = res.data.filter(item => item.is_free === 1);
			const paidAds = res.data.filter(item => item.is_free === 0);
			setfreeAds(freeAds);
			setpaidAds(paidAds);
			setspinloader(0)
		}
	};

	// // Function to save search history
	// const saveSearchHistory = async (adId) => {
	// 	try {
	// 		if (!loginData) {
	// 			return;
	// 		}
	// 		let data = await SaveSearchHistoryAction({ title: title, category: category, state: state, suburb: suburb, memberid: loginData?.id, ad_id: adId });
	// 		console.log(data)
	// 	} catch (error) {
	// 		console.error('Error saving search history:', error);
	// 	}
	// };

	const getAllAdsCountAPI = async () => {
		settotalPages(0);
		settotalRecords(0);
		setpagingspinloader(0);
		let res = await SearchAlladsCountAction({ title: title, category: category, state: state, suburb: suburb });
		if (res.success) {
			settotalPages(res.data);
			settotalRecords(res.totalRecords);
			setpagingspinloader(0);
		}
	};

	const onPageChanged = async (data) => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await SearchAlladsAction({ title: title, category: category, state: state, suburb: suburb, page: offset });
		if (res.success) {
			const freeAds = res.data.filter(item => item.is_free === 1);
			const paidAds = res.data.filter(item => item.is_free === 0);
			setfreeAds(freeAds);
			setpaidAds(paidAds);
		}
		else {
			setfreeAds({});
			setpaidAds({});
		}
	}

	// function to redirect to ad url on click ad title
	const handleAdUrl = (row) => {
		
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'ad/' + row.id + '/' + row.parent_category_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.category_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();
	 	window.location.href = `${pagenewUrl}`;
	
	SaveSearchHistoryAction({ title: row.title, category:row.parent_category_name, state:row.area,  suburb: row.suburb, memberid: loginData.id, adid: row.id });
		console.log(row)
	}
	
	const handleOver18 = () => {
		Cookies.set('ageVerified', 'true', { expires: 0.0208333 });	// for 30 minuts 30 / (24 * 60)
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};

	const handleUnder18 = () => {
		var pagenewUrl = config.baseUrl;
		window.location.href = `${pagenewUrl}`;
	};

	return (
		<>
			<Header />

			{catDetails.cat_desc != '' ? (
				<div className="product-single">
					<div className="container">
						{/* <div className="font-weight-bold mb-3" dangerouslySetInnerHTML={{ __html: catDetails?.cat_desc }}></div> */}
					</div>
				</div>
			) : ''}

			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							{
								spinloader === 0 ? (
									<>

										<div className="buy-end-sell">
											<h2 className="page-heading">Search Results for {title}</h2>
											{allpaidAds.length > 0 ?
												<div className="heading-super">
													<h6>PREMIUM LISTINGS</h6>
												</div>
												: ''}
											{allpaidAds.length > 0 ?
												<div id="premiumads">
													{allpaidAds.map((row, index) => (
														<div key={index} className={index % 2 == 0 ? 'section-listing' : 'section-listing section-listing-new'}>
															<div className="main-item">
																<div className="fast-block">
																	{parseInt(row.pcat_id) == parseInt(config.cat_per) && !ageVerified ? (
																		<img src={config.baseUrl + "img/mcr.png"} alt="" />
																	)
																		:
																		(row.addImg ?
																			<img src={config.apiUrl + row.addImg} alt={row.title} />
																			: <img src={config.baseUrl + "img/noImage.png"} alt={row.title + row.id} />
																		)
																	}
																</div>

																<div className="second-block">
																	{parseInt(row.pcat_id) == parseInt(config.cat_per) && !ageVerified ? (
																		<Link className="fordhead " to="javascript:;" onClick={() => handleAdUrl(row)}>Possible Adult Only content click here to view.</Link>
																	)
																		:
																		(
																			<Link className="fordhead " to="javascript:;" onClick={() => handleAdUrl(row)}>{row.title}</Link>
																		)
																	}


																	{row.sell_price != 0 && row.sell_price != '' ? (
																		<>
																			<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${row.sell_price}</p>
																		</>
																	) : ''
																	}
																	<p><span><i className="fa-solid fa-location-dot"></i></span> {row.suburb} {row.area}</p>
																</div>
															</div>
														</div>
													))}
												</div>
												: ''}

											{allfreeAds.length > 0 ?
												<div id="freeads">
													{allfreeAds.map((row, index) => (
														<div key={index} className={index % 2 == 0 ? 'section-listing bg-even' : 'section-listing bg-odd'}>
															<div className="main-item">
																<div className="fast-block">
																	{parseInt(row.pcat_id) == parseInt(config.cat_per) && !ageVerified ? (
																		<img src={config.baseUrl + "img/mcr.png"} alt="" />
																	)
																		:
																		(row.addImg ?
																			<img src={config.apiUrl + row.addImg} alt={row.title} />
																			: <img src={config.baseUrl + "img/noImage.png"} alt={row.title + row.id} />
																		)
																	}
																</div>

																<div className="second-block">
																	{parseInt(row.pcat_id) == parseInt(config.cat_per) && !ageVerified ? (
																		<Link className="fordhead " to="javascript:;" onClick={() => handleAdUrl(row)}>Possible Adult Only content click here to view.</Link>
																	)
																		:
																		(
																			<Link className="fordhead " to="javascript:;" onClick={() => handleAdUrl(row)}>{row.title}</Link>
																		)
																	}

																	{row.sell_price != 0 && row.sell_price != '' ? (
																		<>
																			<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${row.sell_price}</p>
																		</>
																	) : ''
																	}

																	<p><span><i className="fa-solid fa-location-dot"></i></span> {row.suburb} {row.area}</p>
																</div>
															</div>
														</div>
													))}
												</div>
												: ''}

											{totalPages > 0 ?
												<CustomPagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged} />
												: ''
											}
											{allfreeAds.length <= 0 && allpaidAds.length <= 0 ?
												<div>
													<p><strong>No ads found.</strong></p>
												</div>
												: ''}

										</div>
									</>
								) : (
									<div className="buy-end-sell">
										<div className="loaderDiv">
											<img className="loaderImg" src={config.baseUrl + "img/loader.gif"} height={50} width={50} />
										</div>
									</div>
								)}
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)
}

export default SearchAds;
