import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { parentCatDetailsAction, categoryAlladsAction, categoryAlladsCountAction, SaveFavouriteAdsAction, getMemberFavouriteAdsAction } from '../Action/action';
import { latestAdsAction, featuredAdsAction, homeSlidersAction, homeHozAdsAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));



const Home = () => {
	const maxLength = 40;
	const [latestAds, setLatestAds] = useState({});
	const [featuredAds, setfeaturedAds] = useState({});
	const [homeSliders, sethomeSliders] = useState({});
	const [homeHozAds, sethomeHozAds] = useState({});
	const [adUrl, setAdUrl] = useState('');
	const [getfavAds, setgetfavAds] = useState([])


	useEffect(() => {
		if (!loginData) {

			return null
		}
		else {
			GetFavoriteAds()

		}
		getlatestAds();
		getfeaturedAds();
		gethomeSliders();
		gethomeHozAds();

	}, [])

	const getlatestAds = async () => {
		let res = await latestAdsAction();
		if (res.success) {
			setLatestAds(res.data);
		}
	};
	const GetFavoriteAds = async () => {
		try {
			if (!Cookies.get('loginSuccessMember')) {
				
			return null
			}
			else {

				const res = await getMemberFavouriteAdsAction({ memberid: loginData?.id });

				if (res.success) {

					const adId = res.data;
					setgetfavAds(adId)

				} else {

					toast.error(`Failed to fetch favorite ads: ${res.error}`);
				}
			}
		} catch (error) {

			toast.error(`An error occurred while fetching favorite ads: ${error}`);
		}
	};



	// favouriate ads
	const handleFavorite = async (adId) => {
		if (!Cookies.get('loginSuccessMember')) {
			toast.error('Please login first.');
			return null
		}
		try {
			let res = await SaveFavouriteAdsAction({
				memberid: loginData.id,
				adid: adId
			});
			if (res.success) {
				toast.success(res.msg);
				GetFavoriteAds()
			}
			else {
				toast.error(res.msg);
				GetFavoriteAds()
			}
		} catch (error) {
			console.error('Error saving favorite ad:', error);
			toast.error('Failed to save ad to favorites');
		}
	};

	const getfeaturedAds = async () => {
		let res = await featuredAdsAction();
		if (res.success) {
			setfeaturedAds(res.data);
		}
	};

	const gethomeSliders = async () => {
		let res = await homeSlidersAction();
		if (res.success) {
			sethomeSliders(res.data);
		}
	};

	const gethomeHozAds = async () => {
		let res = await homeHozAdsAction();
		if (res.success) {
			sethomeHozAds(res.data);
		}
	};

	// function to redirect to ad url on click ad title
	const handleAdUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'ad/' + row.id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		window.location.href = `${pagenewUrl}`;
	};

	// function to create ad url on hover ad title
	const getAdUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'ad/' + row.id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		setAdUrl(pagenewUrl);
	};

	// eslint-disable-next-line no-undef
	$('#featuredadslides').not('.slick-initialized').slick({
		vertical: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		arrows: false,
		dots: false,
		infinite: true,
	});

	return (
		<>
			<Header />
			<Toaster />
			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<div className="buy-end-sell">
								<div className="heading-super">
									<h6>LATEST LISTINGS</h6>
								</div>
								<div className="row">
									<div className="col-md-5">
										<div className="jobs-section">
											<ul >
												{latestAds.length > 0 ?
													latestAds.map((row) => (
														<li className="manu-bg">
															<Link to="" >{row.parent_cat}</Link>
														</li>
													)) : ''}

											</ul>
										</div>
									</div>
									<div className="col-md-7">
										<div className="jobs-section">
											<ul>
												{latestAds.length > 0 ?
													latestAds.map((row) => (
														<li className="manu-bg">
															<Link to={adUrl} onClick={() => handleAdUrl(row)} onMouseOver={() => getAdUrl(row)} onMouseOut={() => setAdUrl('')}>{row.title.length > 40 ? row.title.substring(0, 40) + '...' : row.title}</Link>
														</li>
													)) : ''}
											</ul>
										</div>
									</div>
								</div>

								<div className="banner-post">
									<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
										<div className="carousel-inner">
											{homeSliders.length > 0 ?
												homeSliders.map((row, index) => (
													<div className={index == 0 ? 'carousel-item active' : 'carousel-item'}>
														<Link to={row.slide_url} target='_blank'>
															<img className="d-block w-100" src={config.bannersUrl + row.image_url} alt="Home banners" />
														</Link>
													</div>
												))
												: ''}
										</div>
										<a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
											<span className="carousel-control-prev-icon" aria-hidden="true"></span>
											<span className="sr-only">Previous</span>
										</a>
										<a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
											<span className="carousel-control-next-icon" aria-hidden="true"></span>
											<span className="sr-only">Next</span>
										</a>
									</div>
								</div>

								{featuredAds.length > 0 ?
									<div className="heading-super">
										<h6>FEATURED LISTINGS</h6>
									</div>
									: ''}
								{featuredAds.length > 0 ?
									<div id="featuredadslides">
										{featuredAds.map((row, index) => (
											<div className={index % 2 == 0 ? 'section-listing' : 'section-listing section-listing-new'}>
												<div className="main-item">
													<div className="fast-block">
														{row.addImg ?
															<img src={config.apiUrl + row.addImg} alt={row.title} />
															: <img src="img/noImage.png" alt={row.title + row.id} />
														}
													</div>

													<div className="second-block">
														<Link className="fordhead " to={adUrl} onClick={() => handleAdUrl(row)} onMouseOver={() => getAdUrl(row)} onMouseOut={() => setAdUrl('')}> {row.title} </Link>

														<div className="price">
															<p><strong>${row.sell_price}</strong></p>
														</div>
														<p><span><i className="fa-solid fa-location-dot"></i></span> {row.suburb} ({row.area})</p>
													</div>
													{getfavAds.includes(row.id) ?
														(<a
															className="fevcls"
															title=""
															onClick={() => handleFavorite(row.id)}
														>
															<i className="fa fa-heart" aria-hidden="true"></i>
														</a>)
														:
														(<a
															className="fevcls"
															title=''
															onClick={() => handleFavorite(row.id)}
														>
															<i
																className="fa-regular fa-heart" aria-hidden="true"	></i>
														</a>
														)
													}
												</div>
											</div>
										))}
									</div>
									:
									<> <div className="loaderDiv">
										<img className='loaderImg' src="img/loader.gif" height={50} width={50} />
									</div></>

								}

								<div className="banner-post mt-3">
									{homeHozAds.length > 0 ?
										homeHozAds.map((row) => (
											<Link to={row.ad_url} target='_blank'>
												<img src={config.bannersUrl + row.ad_file} alt='banner' />
											</Link>
										))
										: ''}
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)
}

export default Home;
