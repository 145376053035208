const config = {
    baseUrl: '/',
    // apiUrl: "http://localhost:3008/api/",
    // imageUrl: 'http://localhost:3008/api/uploads/',
    // bannersUrl: 'http://localhost:3008/api/banners/',
    apiUrl: "https://bondi.lyonsdemo.in/apis/api/",
    imageUrl: 'https://bondi.lyonsdemo.in/apis/api/uploads/',
    bannersUrl: 'https://bondi.lyonsdemo.in/apis/api/banners/',
    site_key: "6Ld2fj8qAAAAABUcv4g6van-35w8dZegjeLL4ESi",
    secret_key: "6LdtvU0qAAAAAJa4yp0sfPb6A3d0nbK83WzTLJNc",
    superAdmin: 1,
    cat_per: 1,
    cat_real: 2,
    cat_buy: 3,
    cat_whats: 4,
    cat_jobs: 5,
    cat_eat: 6,
    cat_business: 7,
    paging_size: 25
}
export default config;
