import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { InputMask } from '@react-input/mask';
import config from "../coreFIles/config";
import JoditEditor from "jodit-react";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { getCategoriesAction, getnavChildCategoriesAction, getSearchSuburbAction, getPlansAction, getPlanDetailsAction, createAdAction } from '../Action/action';


const AddAd = () => {
	
	if (!Cookies.get('loginSuccessMember')) {
		window.location.href = `${config.baseUrl}`;
	}
	
	const editorHeight = '500px';
	const [spinloader, setspinloader] = useState(0);
	const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));
	const [categoriesList, setCategoriesList]	= useState({});
	const [plansList, setPlansList]				= useState({});
	const [childcatsList, setchildCats]			= useState([]);
	const [allcatChilds, setallcatChilds]			= useState([]);
	const [SuburbsearchResults, setSuburbsearchResults]	= useState({});
	const [suburb, setsuburb] = useState('');
	const [validatioError, setvalidatioError]	= useState({});
	const [currentDate, steCurrentDate]		= useState(new Date());
	const [selectedOption, setSelectedOption] = useState(0);
	
	
	const [form, setForm]	= useState({
		parent_id: '',
		category_id: '',
		title: '',
		description: '',
		suburb: '',
		age_group: '',
		area: '',
		gender: '',
		images: {},
		phone: '',
		yt_video_url: '',
		tags: '',
		hide_my_id: 0,
		status: 1,
		plan_id: 0,
		whatson_date: '',
		event_type: '',
		price: '',
		property_type: '',
		bedrooms: '',
		unit_flat: '',
		street_no: '',
		street_name: '',
		work_type: '',
		abn: '',
		accreditation: '',
		from_date: '',
		to_date: '',
		planDays: '',
		planAmount: '',
		member_id: loginData?.id,
	});
	useEffect(() => {
		getCategories();
		getCategoriesChilds();
		getPlans();
	}, [])

	const getCategories = async (e) => {
		let res = await getCategoriesAction();
		if(res.success){
			setCategoriesList(res.data)
		}
	}
	
	const getCategoriesChilds = async () => {
		let res = await getnavChildCategoriesAction();
		if (res.success) {
			setallcatChilds(res.data);
		}
	};
	
	const getPlans = async (e) => {
		let res = await getPlansAction();
		if(res.success){
			setPlansList(res.data)
		}
	}

	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			if(str != ''){
				let res = await getSearchSuburbAction({str:str});
				setSuburbsearchResults(res.data);
				
				setForm((old) => {
					return { ...old, 'suburb': str };
				});
			}
			else{
				setSuburbsearchResults('');
			}
		}
		catch (error) {
			console.error(error);
		}
	};

	const handleSelectSuburb = (row) => {
		setsuburb(row);
		
		setForm((old) => {
			return { ...old, 'suburb': row };
		});
		
		setSuburbsearchResults({});
	};

	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}

	const inputfileHandler = (e) => {
		e.preventDefault()
		const files = Array.from(e.target.files);
		
	   	setForm((old) => {
			return { ...old, ['images'] : files }
	   	})
	};

	function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

	function adddaysToDate(days) {
		var cdate	= new Date();
		var result	= cdate.setDate(cdate.getDate() + days);
		var newdate	= formatDate(result);
		
        return newdate;
    }

	const whatsonDate = e => {
        let whtsonDate = formatDate(e);
		setForm((old) => {
			return { ...old, 'whatson_date': whtsonDate }
		})
    }

	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		
		if (!isValidInput) {
			e.preventDefault();
		}
	};

	const handleSelect = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}

	const checkboxHandler = (e) => {
		const { name, value } = e.target;
		if (e.target.checked) {
			setForm((old) => {
				return { ...old, [name]: value };
			});
		} else {
			setForm((old) => {
				return { ...old, [name]: 0 };
			});
		}
	};

	const descriptionHandler = async (e) => {
		setForm((old) => {
		  return { ...old, 'description': e }
		})
	}

	const handleParentCat = async(id) => {		
		if(parseInt(id)==parseInt(config.cat_per)){
			setForm((old) => {
				return { ...old,
					'parent_id': id,
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(parseInt(id)==parseInt(config.cat_real)){
			setForm((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(parseInt(id)==parseInt(config.cat_buy)){
			setForm((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(parseInt(id)==parseInt(config.cat_whats)){
			setForm((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(parseInt(id)==parseInt(config.cat_jobs)){
			setForm((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(parseInt(id)==parseInt(config.cat_eat)){
			setForm((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
					'abn': '',
					'accreditation': '',
				};
			});
		}
		else if(parseInt(id)==parseInt(config.cat_business)){
			setForm((old) => {
				return { ...old,
					'parent_id': id,
					'age_group': '',
					'gender': '',
					'property_type': '',
					'bedrooms': '',
					'unit_flat': '',
					'street_no': '',
					'street_name': '',
					'price': '',
					'whatson_date': '',
					'event_type': '',
					'work_type': '',
				};
			});
		}
		const result = allcatChilds.filter(data => String(data.parent_id).includes(id)); 
		if (result.length > 0) {
			setchildCats(result); 
		}
		else{
			setchildCats({});
		}
		
	};

	const handleChange = async(e) => {
		setSelectedOption(e.target.value);
		if(e.target.value!=0)
		{
		let date		= new Date();
		let fromDate	= formatDate(date);
		let res = await getPlanDetailsAction({id:e.target.value});
		if(res.success){
			let toDate	= adddaysToDate(res.data.days);
			
			setForm((old) => {
				return { ...old,
					'from_date': fromDate,
					'to_date': toDate,
					'planDays': res.data.days,
					'planAmount': res.data.price,
					'plan_id': e.target.value
				}
			})
			
		}else{
			setForm((old) => {
				return { ...old,
					'from_date': '',
					'planDays': '',
					'to_date': '',
					'planAmount': '',
					'plan_id': 0
				}
			})
		}
	}else{
			setForm((old) => {
				return { ...old,
					'from_date': '',
					'planDays': '',
					'to_date': '',
					'planAmount': '',
					'plan_id': 0
				}
			})
		}
}
	
	const inputPhoneHandler = async (e) => {
		const { name, value } = e.target
		
		  setForm((old) => {
				return { ...old, [name]: value }
			})
				
	}
	const handleKeyDown = (e) => { 
    if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
      e.preventDefault();
    }
  };

	function validate() {
		let parentError = "";
		let categoryError = "";
		let titleError = "";
		let descriptionError = "";
		let suburbError = "";
		let areaError = "";
		let statusError = "";

		if (form.parent_id === "") {
			parentError = "Category is required.";
		}
		
		if (form.category_id === "") {
			categoryError = "Sub Category is required.";
		}
		
		if (form.title === "") {
			titleError = "Ad title is required.";
		}
		
		if (form.description === "") {
			descriptionError = "Description is required.";
		}
		
		if (form.status === "") {
			statusError = "Status is required.";
		}
		
		if (form.suburb === "") {
			suburbError = "Suburb is required.";
		}
		
		if (form.area === "") {
			areaError = "Area is required.";
		}

		if (titleError || parentError || categoryError || descriptionError || statusError || suburbError || areaError) {
			setvalidatioError({
				titleError,parentError,categoryError,descriptionError,statusError,suburbError,areaError });
			return false;
		}
		else {
			setvalidatioError({
			titleError,parentError,categoryError,descriptionError,statusError,suburbError,areaError });
			return true;
		}
	}

	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		
		if (!isValid) {

		}
		else {
			setspinloader(1);
			let res = await createAdAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}manage-ads`;
				}, 2000);
			}			 
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		}
	}

	return(
		<>
			<Header />

			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />

							<div className="classification-section">
								<h2 className="page-heading"> Post My Classified </h2>

								<form onSubmit={SubmitForm}>
									<div className="table-responsive table-striped ">
										<table className="table">
											<tbody>
												<tr>
													<th colSpan="6" className="basicclassified text-center"> BASIC CLASSIFIED</th>
													<th className="planInput darkbluebg text-center">
														<span id="basicclassifieds"> </span>
														<input type="radio" value="0" checked={selectedOption == '0'} onChange={handleChange} />
													</th>
												</tr>
												<tr>
													<td colSpan="7" className="planInfo text-center">(or) </td>
												</tr>
												<tr className="basicclassified lightbluebg">
													<td colSpan="7" className="planInfo text-center"> PREMIUM CLASSIFIED </td>
												</tr>
												<tr>
													<td colSpan="7" className="planInfo"> With premium upgrade your listing will stand out. It will always be highlighted in the top section of your chosen category and also appear as featured classified on homepage. </td>
												</tr>
												<tr className="text-center lightbluebg">
													{plansList.length > 0 ? 
														plansList.map((row, index) => (
															<td key={index} className="plan">{row.days} Days<br />${row.price}</td>
														))
													: ''}
												</tr>
												<tr className="text-center darkbluebg">
													{plansList.length > 0 ? 
														plansList.map((row, index) => (
															<td key={index} className="planInput"><input className="required" type="radio" value={row.plan_id} checked={selectedOption == row.plan_id}  onChange={handleChange} /></td>
														))
													: ''}
												</tr>
											</tbody>
										</table>
									</div>

									<div className="bondiclassified-fromsection">
										<div className="catagoryfrom">
											<div className="row">
												<div className="col-md-6 col-sm-12">
													<div className="form-group">
														<label for="parent_id">Category</label>
														<select className="form-control" id="parent_id" onChange={(e) => handleParentCat(e.target.value)}>
															<option value='0'>Select</option>
															{categoriesList.length > 0 ?
																categoriesList.map((row, index) => (
																	<option key={index} value={row.cat_id}>{row.cat_name}</option>
																))
															: ''}
														</select>
														<span className="text-danger">
															{validatioError.parentError}
														</span>
													</div>
												</div>
												
												<div className="col-md-6 col-sm-12">
													<div className="form-group">
														<label for="category_id">Category</label>
														<select className="form-control" id="category_id" name="category_id" onChange={handleSelect}>
															<option>Select</option>
															{childcatsList.length > 0 ?
																childcatsList.map((row, index) => (
																	<option key={index} value={row.cat_id}>{row.cat_name}</option>
																))
															: ''}
														</select>
														<span className="text-danger">
															{validatioError.categoryError}
														</span>
													</div>
												</div>
												
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="title">Title</label>
														<input type="text" className="form-control" id="title" name='title' onChange={inputHandler} />
														<span className="text-danger">
															{validatioError.titleError}
														</span>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label for="desctiption">Description</label>
														 &nbsp; <span className="text-danger">
															{validatioError.descriptionError}
														</span>
														<JoditEditor onChange={descriptionHandler} value='' name="description" id="description" style={{ height: editorHeight }} className="" placeholder="Enter description" />
													</div>
												</div>
												
												<div className="col-md-6">
													<div className="form-group">
														<label for="suburb">Suburb</label>
														<input type="text" name="suburb" className="form-control" id="suburb"  onChange={(e) => SearchSuburb(e.target.value)} autoComplete="off" value={suburb} />
														
														<span className="text-danger">
															{validatioError.suburbError}
														</span>
														{SuburbsearchResults.length > 0 ? (
															<ul className="suburbList">
																{SuburbsearchResults.map((row) => (
																	<li key={row.id} onClick={() => handleSelectSuburb(row.rowlocation)}>
																		{row.rowlocation} 
																	</li>
																))}
															</ul>
														) : null}
													</div>
												</div>
												
												<div className="col-md-6">
													<div className="form-group">
														<label for="area">Area</label>
														
														<select name='area' className="form-control" id='areaError' onChange={handleSelect}>
															<option value="">Select</option>  
															<option value="Australia Wide">Australia Wide</option>
															<option value="NSW - Sydney">NSW - Sydney</option>
															<option value="NSW - Regional">NSW - Regional</option>
															<option value="QLD - Brisbane">QLD - Brisbane</option>
															<option value="QLD - Regional">QLD - Regional</option>
															<option value="VIC - Melbourne">VIC - Melbourne</option>
															<option value="VIC - Regional">VIC - Regional</option>
															<option value="WA - Perth">WA - Perth</option>
															<option value="WA - Regional">WA - Regional</option>
															<option value="SA - Adelaide">SA - Adelaide</option>
															<option value="SA - Regional">SA - Regional</option>
															<option value="NT - Darwin">NT - Darwin</option>
															<option value="NT - Regional">NT - Regional</option>
															<option value="TAS - Hobart">TAS - Hobart</option>
															<option value="TAS - Regional">TAS - Regional</option>
														</select>
														
														<span className="text-danger">
															{validatioError.areaError}
														</span>
													</div>
												</div> 
											</div>
										</div>
										
										<div className="catagory-attchment">
											<div className="col-md-12">
												<div className="form-group">
													<label for="ad_image">Drag & Drop your images here OR simply click the "Select Files" button to upload.</label>
													<input type="file" className="form-control-file" id="ad_image" name='ad_image' onChange={inputfileHandler} multiple />
												</div>
											</div>
										</div>
										
										<div className="catagoryfrom">
											<div className="row">
												{ parseInt(form?.parent_id)=== parseInt(config.cat_per)?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">
																	Age Group
																</label>
																
																<select name='age_group' className="form-control" id='age_groupError' onChange={handleSelect}>
																	<option value="">Select</option>
																	<option value="18-22">18-22</option>
																	<option value="23-26">23-26</option>
																	<option value="27-30">27-30</option>
																	<option value="31-36">31-36</option>
																	<option value="37-42">37-42</option>
																	<option value="43-50">43-50</option>
																	<option value="50-60">50-60</option>
																	<option value="over 61">over 61</option>
																</select>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">
																	Gender
																</label>
																
																<select name='gender' className="form-control" id='genderError' onChange={handleSelect}>
																	<option value="">Select</option>
																	<option value="0">Male</option>
																	<option value="1">Female</option>
																</select>
															</div>
														</div>
													</>
												: ''}
												{ parseInt(form?.parent_id)=== parseInt(config.cat_real)?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Property Type</label>
																
																<select name='property_type' className="form-control" id='property_typeError' onChange={handleSelect}>
																	<option value="">Select</option>
																	<option value="All">All</option>
																	<option value="Studio">Studio</option>
																	<option value="Apartment">Apartment</option>
																	<option selected="selected" value="House">House</option>
																	<option value="Rural">Rural</option>
																</select>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Bedrooms</label>
																
																<select name='bedrooms' className="form-control" id='bedroomsError' onChange={handleSelect}>
																	<option value="">Select</option>
																	<option value="All">All</option>
																	<option value="1 or more">1 or more</option>
																	<option value="2 or more">2 or more</option>
																	<option value="3 or more">3 or more</option>
																	<option value="4 or more">4 or more</option>
																</select>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Unit/Flat</label>
																
																<input type="text" name='unit_flat' className="form-control" id='unit_flatError'  onChange={inputHandler} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Street No</label>
																
																<input type="text" name='street_no' className="form-control" id='street_noError'  onChange={inputHandler} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Street Name</label>
																
																<input type="text" name='street_name' className="form-control" id='street_nameError'  onChange={inputHandler} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Price</label>
																
																<input type="text" name='price' className="form-control" id='priceError'  onChange={inputHandler} onKeyPress={handleKeyPress} />
															</div>
														</div>
													</>
												: ''}
												{ parseInt(form?.parent_id)=== parseInt(config.cat_buy)?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Price</label>
																
																<input type="text" name='price' className="form-control" id='priceError'  onChange={inputHandler} onKeyPress={handleKeyPress} />
															</div>
														</div>
													</>
												: ''}
												{ parseInt(form?.parent_id)=== parseInt(config.cat_whats)?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">When is the Event?</label>
																
																<DatePicker onChange={whatsonDate}  placeholder ="Select Date" autoComplete="off" name="whatson_date" id="whatson_dateError" className='form-control' value={form.whatson_date} minDate={currentDate}  />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">
																	Day/Night Event?
																</label>
																
																<select name='event_type' className="form-control" id='event_typeError' onChange={handleSelect}>
																	<option value="">Select</option>
																	<option value="Day">Day</option>
																	<option value="Night">Night</option>
																</select>
															</div>
														</div>
													</>
												: ''}
												{ parseInt(form?.parent_id)=== parseInt(config.cat_jobs)?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Work Type</label>
																
																<select name='work_type' className="form-control" id='work_typeError'  onChange={handleSelect}>
																	<option value="">Select Work Type</option>
																	<option value="All">All</option>
																	<option value="Full Time">Full Time</option>
																	<option value="Part Time">Part Time</option>
																	<option value="Contract/Temp">Contract/Temp</option>
																	<option value="Freelance">Freelance</option>
																</select>
															</div>
														</div>
													</>
												: ''}
												{ parseInt(form?.parent_id)=== parseInt(config.cat_business)?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">ABN (Australian Business Number)</label>
																<input className="form-control" type="text" name="abn" id='abnError' onChange={inputHandler} placeholder="Enter Australian Business Number" onKeyPress={handleKeyPress} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Accreditation</label>
																
																<input className="form-control" type="text" name="accreditation" id='accreditationError' onChange={inputHandler} placeholder="Enter Accreditation"  />
															</div>
														</div>
													</>
												:''}
												
												<div className="col-md-6 col-sm-6">
													<div className="form-group">
														<label for="phone">Phone</label>
														<InputMask
                                                            type='text'
                                                            className='form-control'
                                                            name='phone'
                                                            id='phone'
                                                            value={form.phone}
                                                            onChange={inputPhoneHandler}
                                                            onKeyDown={handleKeyDown}
                                                            mask="____ ___ ___" 
                                                            replacement={{ _: /\d/ }}
                                                            placeholder="____ ___ ___"
                                                            
                                                        />
													</div>
												</div>
												
												<div className="col-md-6 col-sm-6">
													<div className="form-group">
														<label for="tags">Tags</label>
														<input type="text" className="form-control" id="tags" name="tags" onChange={inputHandler} />
													</div>
												</div>
												
												<div className="col-md-6 col-sm-6">
													<div className="form-group">
														<label for="yt_video_url">Youtube Video URL Code</label>
														<input type="text" className="form-control mb-2" id="yt_video_url" name='yt_video_url' onChange={inputHandler} />
														<img src={config.baseUrl + "img/urlimg.jpg"} alt='ad-yt-video' />
													</div>
												</div>
												
												<div className="col-md-6 col-sm-6">
													<div className="form-group hideblock">
														<label for="formGroupExampleInput">Hide My ID</label><br />
														<input type="checkbox" id="hide_my_id" name="hide_my_id" value="1" onChange={checkboxHandler} />
													</div>
												</div>
												
												<div className="col-md-12 text-center mt-3 pdb-20 submitsection">
												{spinloader == '0' ?
													<button type="submit" className="btn btn-primary submitbtn">Submit</button>
													:
													<button disabled className='btn btn-primary submitbtn' >Submiting.. <i className="fa fa-spinner fa-spin validat"></i></button>
													}
													
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)

}

export default AddAd;
