import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../coreFIles/config'
import { getFooterCategoriesAction, getContentByKeywordAction, getfooterMenuAction } from '../Action/action';


const Footer = () => {
	const [categoriesData, setCategoriesData] = useState({});
	const [childCatsCountData, setCatsCountData] = useState({});
	const [CategoryChildData, setCategoryChildData] = useState({});
	const [footerDesc, setfooterDesc] = useState({});
	const [footerMenu, setfooterMenu] = useState({});
	const [rowBreak, setrowBreak] = useState(35);
	const [catcolumns, setcatcolumns] = useState([]);
	const [loader, setloader] = useState(0);
	

	useEffect(() => {
		getFooterCategoriesData();
		getfooterDesc();
		getfooterMenu();
	}, [])

	const getFooterCategoriesData = async () => {
		let res = await getFooterCategoriesAction();
		if (res.success) {
			let cats = res.data;
			let childs = res.childs;
			setCategoriesData(cats);
			setCategoryChildData(childs);
			setCatsCountData(res.totalChildCount);
			let rbreak = Math.ceil((res.totalChildCount) / 4);
			setloader(1);

			let columns = [[], [], [], []];
			let catCount = 0;
			let columnIndex = 0;

			cats.forEach((row) => {
				if (row.countChild > 0) {
					let childElements = [];
					childs.filter(ctrow => row.cat_id === ctrow.parent_id).forEach((ctrow, subIndex) => {
						catCount++;
						if (catCount >= rbreak) {
							catCount = 0;
							columns[columnIndex].push(
								<div key={`parent-${row.cat_id}-${subIndex}`}>
									<h6 className="busyheading">{row.cat_name}</h6>
									<ul>
										{childElements}
										<li key={subIndex} data-key={subIndex}>
											<Link to="javascript:;" onClick={() => handleCatUrl(ctrow)}>{ctrow.cat_name} <b>({ctrow.ads})</b></Link>
										</li>
									</ul>
								</div>
							);
							childElements = [];
							columnIndex = (columnIndex + 1) % 4;
						} else {
							childElements.push(
								<li key={subIndex} data-key={subIndex}>
									<Link to="javascript:;" onClick={() => handleCatUrl(ctrow)}>{ctrow.cat_name} <b>({ctrow.ads})</b></Link>
								</li>
							);
						}
					});

					if (childElements.length > 0) {
						columns[columnIndex].push(
							<div key={`parent-${row.cat_id}`}>
								<h6 className="busyheading">{row.cat_name}</h6>
								<ul>{childElements}</ul>
							</div>
						);
					}
				}
			});

			setcatcolumns(columns);
			setloader(0);
		}
	};

	const handleViewAll = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'cat/' + row.cat_id + '/action/all/' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		window.location.href = `${pagenewUrl}`;
	};
	const handleCatUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'cat/' + row.cat_id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		window.location.href = `${pagenewUrl}`;
	};


	const getfooterDesc = async () => {
		let res = await getContentByKeywordAction({ keyword: 'footer' });
		if (res.success) {
			setfooterDesc(res.data);
		}
	};

	const getfooterMenu = async () => {
		let res = await getfooterMenuAction();
		if (res.success) {
			setfooterMenu(res.data);
		}
	};

	const handleContentUrl = (id) => {
		var pagenewUrl = '';
		if (id == 1) {
			pagenewUrl = config.baseUrl + 'about'
		}
		else if (id == 2) {
			pagenewUrl = config.baseUrl + 'advertise-with-us'
		}
		else if (id == 3) {
			pagenewUrl = config.baseUrl + 'help'
		}
		else if (id == 5) {
			pagenewUrl = config.baseUrl + 'terms-and-conditions'
		}
		else if (id == 6) {
			pagenewUrl = config.baseUrl + 'privacy-policy'
		}
		window.location.href = `${pagenewUrl}`;
	};

const changeLocation =()=>{
	window.location.href = `${config.baseUrl}`;
}
const contactus =()=>{
	setInterval(() => {
		window.location.href = `${config.baseUrl}`;
	}, 1000);
	
}
	return (
		<>

			<div className="business">
				<div className="container">
					<div className="row">

						{loader == 0 ?
							catcolumns.map((column, colIndex) => (
								<div key={colIndex} className="col-lg-3 col-sm-6">
									<div className="business-services">
										{column}
									</div>
								</div>
							))
							: ''
						}
					</div>
				</div>
			</div>

			<div className="footer-section">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="footertext">
								<div dangerouslySetInnerHTML={{ __html: footerDesc?.content }} ></div>

								<div className="privacy-policy">
								<Link to={config.baseUrl}>Home</Link>
								<Link to={config.baseUrl + 'contact-us'}>contact us</Link>
									{footerMenu.length > 0 ?
										footerMenu.map((row, index) => (
											<Link key={index} to="javascript:;" onClick={() => handleContentUrl(row.content_id)}>{row.title}</Link>
										))
										: ''}
									
								</div>
								
								<div className="footericon">
									<div className="facebook">
									<Link to="https://www.facebook.com/BondiClassifieds.com.au" target="_blank"><i className="fa-brands fa-facebook-f"></i></Link>
									</div>
									<div className="facebook instagram">
									<Link to="https://www.instagram.com/bondiclassifieds.com.au" target="_blank"><i className="fa-brands fa-instagram"></i></Link>
									</div>
									<div className="facebook twiter">
									<Link to="https://x.com/BondiClassified" target="_blank"><i className="fa-solid fa-x"></i></Link>
									</div>
									<div className="facebook linkdin">
									<Link to="https://www.linkedin.com/showcase/bondiclassifieds/about/"  target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</>
	)
}

export default Footer;
